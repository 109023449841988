import resume from 'assets/downloads/ResumePaulo.pdf';

const social = [
  {
    id: 0,
    name: 'Telegram',
    link: 'https://t.me/phfa26',
    icon: '/icons/telegram.svg',
  },
  {
    id: 1,
    name: 'Github',
    link: 'https://github.com/phfa26/',
    icon: '/icons/github.svg',
  },
  {
    id: 2,
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/in/phfamaral/',
    icon: '/icons/linkedin.svg',
  },
  {
    id: 3,
    name: 'CV',
    link: resume,
    icon: '/icons/cv.svg',
  },
];

export default social;
