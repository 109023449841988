import React from 'react';
import { Container } from 'components/common';
import { Wrapper, Flex, Links, Details } from './styles';
import social from './social.js';

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h2>Paulo Amaral</h2>
        <span>
          © All rights are reserved | {new Date().getFullYear()} | Made with{' '}
          <span aria-label="love" role="img">
            💖
          </span>{' '}
          by{' '}
          <a href="https://pauloamaral.com.au" rel="noopener noreferrer" target="_blank">
            Paulo Amaral
          </a>
          {' | '}Powered by{' '}
          <a href="https://reactjs.org/" rel="noopener noreferrer" target="_blank">
            React
          </a>
          ,{' '}
          <a href="https://www.gatsbyjs.org/" rel="noopener noreferrer" target="_blank">
            Gatsby
          </a>{' '}
          and{' '}
          <a href="https://www.netlify.com/" rel="noopener noreferrer" target="_blank">
            Netlify.
          </a>
        </span>
      </Details>
      <Links>
        {social.map(({ id, name, link, icon }) => (
          <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
            <img width="24" src={icon} alt={name} />
          </a>
        ))}
      </Links>
    </Flex>
  </Wrapper>
);
