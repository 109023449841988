module.exports = {
  defaultTitle: 'Paulo Amaral',
  logo: '../assets/thumbnail/PauloAmaralLogo.png',
  author: 'Paulo Amaral',
  url: 'https://pauloamaral.com.au',
  legalName: 'Paulo Amaral',
  defaultDescription: 'I’m Paulo and I’m a Software Engineer!',
  socialLinks: {
    github: 'https://github.com/phfa26/',
    linkedin: 'https://www.linkedin.com/in/phfamaral/',
  },
  googleAnalyticsID: 'G-NXWXPT18CZ',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  address: {
    city: 'Sydney',
    region: 'NSW',
    country: 'Australia',
    zipCode: '2000',
  },
  contact: {
    email: 'paulo@amaral.com.au',
    phone: '0432442258',
  },
  foundingDate: '2020',
};
